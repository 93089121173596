import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action } from 'vuex-class';
import { EventsCreateRequest } from '@/types/request/events-request.types';
import { EventType } from '@/types/api/events/event.type';
import EventForm from '@/components/pages/events/event-form/eventForm.vue';

@Component({
  components: {
    Layout,
    PageTitle,
    EventForm
  },
})
export default class EventCreate extends Vue {
  @Action('createEvent', { namespace: 'events' })
  createEvent!: (params: EventsCreateRequest) => Promise<EventType>;

  onApply(params: EventsCreateRequest) {
    return this.createEvent(params)
      .then(() => {
        this.$router.push({ name: 'events' });
      });
  }
}
